.navbar {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 30px;

  .user-logout {
    &:hover {
      cursor: pointer;
    }
  }
}

.navbar-nav {
  width: 100%;

  .nav-link {
    &.is-active {
      color: #555;
      background-color: #e7e7e7;
    }
  }
}

.logo {
  &-icon {
    max-width: 300px;
  }

  &-name {
    max-width: 300px;
  }
}

.bg-orange {
  background-color: #f87474
}