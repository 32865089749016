.tutorial {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 70%;
  
    &__column {
      background: #FFFFFF;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      overflow: hidden;
    }
  
    &__column--issuer,
    &__column--verifier {
        width: 50%;
        margin: 0 auto;
    }
  
    &__column--holder {
        width: 70%;
        margin: 0 auto;
    }
  
    &__column-title {
      background-color: #363B98;
      text-align: center;
      text-transform: uppercase;
      line-height: 3;
      color: #ffffff;
    }
  
    &__column-steps {
      padding: 40px;
    }
  
    &__step {
      margin-bottom: 30px;
    }
  
    &__step-text {
      display: block;
      margin-bottom: 5px;
    }
  
    &__status {
      margin-right: 10px;
    }
  
    &__status-input {
      margin-right: 5px;
    }
  
    &__textarea {
      display: block;
      width: 100%;
      height: 450px;
      border-color: #cccccc;
      margin-bottom: 5px;
    }
  
    &__textarea--small {
      height: 200px;
    }
  
    &__textarea-block {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  
    &__verified-vcs {
      margin-top: 40px;
      border-top: 1px solid #cccccc;
      padding-top: 40px;
    }
  }

.json-tree {
  width: 100%;
  background: #fff;
  margin: 20px auto;
  padding: 20px;
}
