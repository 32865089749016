$root-selector: ".intro";

#{$root-selector} {
  &__heading-block {
    margin-bottom: 100px;
    text-align: center;
  }

  &__heading {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-weight: 300;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #a7a7a7;

    > span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__subheading {
    text-align: center;
    width: 100%;
  }

  &__first-letter {
    &:first-letter {
      color: #363e98;
    }
  }

  &__second-letter {
    &:first-letter {
      color: #81d0d8;
    }
  }

  &__third-letter {
    &:first-letter {
      color: #ad9ccb;
    }
  }

  &__text-block {
    margin-bottom: 70px;

    h4 {
      font-weight: 600;
      color: #363b98;
      margin-top: 30px;
    }
  }

  &__example {
    display: flex;
    justify-content: space-around;
    margin-bottom: 70px;

    > * {
      flex-basis: 20%;
    }
  }

  &__example-icon {
    max-width: 140px;
  }

  &__example-role {
    color: #363b98;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
  }

  &__example-role-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  &__example-role-subtitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 15px;
  }

  &__example-role-directions {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__example-direction-text {
    display: block;
    text-align: center;
  }

  &__example-direction-arrow {
    display: block;
    position: relative;
    background: #000000;
    height: 10px;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      margin-left: -1px;
      display: block;
      border: 15px solid transparent;
      border-left-color: #000000;
    }

    &--left {
      &:after {
        left: auto;
        right: 100%;
        margin-right: -1px;
        border-left-color: transparent;
        border-right-color: #000000;
      }
    }
  }

  &__roles-description {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }

  &__roles-description-role {
    width: 30%;
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    background-color: #d4d6fd;

    p {
      font-size: 15px;
      line-height: 1.75;
    }
  }
}

.flow-size {
  max-width: 70vw;
}
