input {
  &[type="checkbox"],
  &[type="radio"] {
    + label {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
